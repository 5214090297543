import { BOARD_SPRINT_FRAGMENT, SPRINT_FRAGMENT } from '#sprint/fragment'
import type { BoardSprintItem, BoardSprintPayload, SprintItem } from '#sprint/types'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { BOARD_SPRINT_TYPE_NAME, SPRINT_TYPE_NAME } from '#sprint/constant'

class BoardSprintListCache extends ModelListCache<BoardSprintItem> {
  constructor() {
    super(BOARD_SPRINT_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [BOARD_CACHE.SPRINT]
  }
}

export const useSprintSync = () => {
  const { client } = useApolloClient()
  const { getSprintIdentifier, getSyncSprintFields } = useSprintDataConvert()

  const onAdd = async (payload: Partial<SprintItem>) => {
    client.cache.writeFragment({
      data: payload,
      fragment: SPRINT_FRAGMENT,
      fragmentName: 'Sprint',
    })
  }

  const onUpdate = (payload: Partial<SprintItem>) => {
    client.cache.modify({
      id: getSprintIdentifier(payload.id as string),
      fields: getSyncSprintFields(payload),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<SprintItem>) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
  }
}

export const useBoardSprintSync = () => {
  const { client } = useApolloClient()
  const listCache = new BoardSprintListCache()
  const { currentBoard } = useWorkspaceSharedState()

  const onAdd = async (payload: Partial<BoardSprintPayload>) => {
    const newBoardSprint = {
      id: payload.id as string,
      sprint: client.cache.readFragment({
        id: getIdentifier(payload.sprintId as string, SPRINT_TYPE_NAME),
        fragment: SPRINT_FRAGMENT,
        fragmentName: 'Sprint',
      }) as SprintItem,
      __typename: BOARD_SPRINT_TYPE_NAME,
    } as BoardSprintItem

    client.cache.writeFragment({
      data: newBoardSprint,
      fragment: BOARD_SPRINT_FRAGMENT,
      fragmentName: 'BoardSprint',
    })

    listCache.add(currentBoard.value.id, newBoardSprint)
  }

  const onDelete = (payload: Partial<BoardSprintPayload>) => {
    if (!payload.id) {
      return
    }

    client.cache.evict({
      id: getIdentifier(payload.id as string, BOARD_SPRINT_TYPE_NAME),
    })

    listCache.delete(currentBoard.value.id, payload.id)
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: Partial<BoardSprintPayload>) => void
        >([
          ['ADD', onAdd],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
