import { pick } from 'lodash-es'
import type { SprintItem } from '#sprint/types'
import { SPRINT_TYPE_NAME } from '#sprint/constant'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useSprintDataConvert = () => {
  const getSprintIdentifier = (id: string) => getIdentifier(id, SPRINT_TYPE_NAME)
  const fields = ['name', 'status', 'startDate', 'endDate', 'description', 'measurement', 'dateFormat']

  // For app.add
  const getOptimisticSprint = (id: string | null, payload: Partial<SprintItem>, forFields = false): Partial<SprintItem> => {
    const defaults = { }
    const sprint = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? sprint : { ...sprint, __typename: SPRINT_TYPE_NAME }
  }

  const getOptimisticSprintFields = (payload: Partial<SprintItem>) => {
    const sprint = getOptimisticSprint(null, payload, true)
    const fields = convertObjectToFields(sprint)
    return fields
  }

  const getSyncSprintFields = (payload: Partial<SprintItem>) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<SprintItem>
  }

  return {
    getSprintIdentifier,
    getOptimisticSprint,
    getOptimisticSprintFields,
    getSyncSprintFields,
  }
}
